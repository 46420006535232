@font-face {
  font-family: 'peyda';
  font-weight: 100;
  src: url('./../public/fonts/peyda/PEYDA-THIN.TTF') format('truetype');
}
@font-face {
  font-family: 'peyda';
  font-weight: 200;
  src: url('./../public/fonts/peyda/PEYDA-EXTRALIGHT.TTF') format('truetype');
}
@font-face {
  font-family: 'peyda';
  font-weight: 300;
  src: url('./../public/fonts/peyda/PEYDA-LIGHT.TTF') format('truetype');
}
@font-face {
  font-family: 'peyda';
  font-weight: 400;
  src: url('./../public/fonts/peyda/PEYDA-REGULAR.TTF') format('truetype');
}
@font-face {
  font-family: 'peyda';
  font-weight: 500;
  src: url('./../public/fonts/peyda/PEYDA-MEDIUM.TTF') format('truetype');
}
@font-face {
  font-family: 'peyda';
  font-weight: 600;
  src: url('./../public/fonts/peyda/PEYDA-SEMIBOLD.TTF') format('truetype');
}
@font-face {
  font-family: 'peyda';
  font-weight: 700;
  src: url('./../public/fonts/peyda/PEYDA-BOLD.TTF') format('truetype');
}
@font-face {
  font-family: 'peyda';
  font-weight: 800;
  src: url('./../public/fonts/peyda/PEYDA-EXTRABOLD.TTF') format('truetype');
}
@font-face {
  font-family: 'peyda';
  font-weight: 900;
  src: url('./../public/fonts/peyda/PEYDA-BLACK.TTF') format('truetype');
}

* {
  font-family: 'peyda';
  direction: rtl;
}

.autoWaveShadow {
  box-shadow: 0px 0px 20px -6px black;
  animation-name: waveShadow;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in;
}
@keyframes waveShadow {
  0% {
    box-shadow: 0px 0px 10px 0px #005716;
  }
  100% {
    box-shadow: 0px 0px 30px 0px #005716;
  }
}
@keyframes home-bg-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
body {
  /* position: relative; */
  overflow-x: hidden;
}
